.sideNav {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-top: 5px;
  width: 300px;
  .sideNav_user_info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    .image {
      width: 150px;
      height: 150px;
      border-radius: 999px;
      img {
        width: 150px;
        height: 150px;
        display: block;
        border-radius: 999px;
        border: 1px solid rgb(122, 117, 117);
      }
    }
  }
  .sideNav_list {
    height: 80px;
    padding-right: 25px;
    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .list {
      list-style: none;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      height: 100%;
      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
      }
      .list_item {
        a {
          text-decoration: none;
          color: rgb(119, 118, 118);
        }
      }
    }
    button {
      background-color: rgb(19, 109, 228);
      border: none;
      border-radius: 15px;
      color: white;
      cursor: pointer;
    }
  }
}
