.layOut {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  flex-wrap: nowrap;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
  }
  .child {
    border-left: 1px solid lightgray;
    width: 100%;
    @media (max-width: 992px) {
      width: 100%;
      border-left: none;
      border-top: 1px solid lightgray;
    }
  }
}
