.upperNav {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px 20px;
  background-color: #0f59ad;
  .contact {
    max-width: 400px;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    span {
      color: rgb(255, 195, 65);
    }
  }
  .social {
    width: 150px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .languages {
    display: flex;
    div {
      width: 50px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .ukflag {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: lightgray;
        padding: 0px 5px;
        width: 40px;
        height: 24px;
      }
    }
    div {
      width: 50px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .greekflag {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: lightgray;
        padding: 0px 5px;
        width: 50px;
        height: 30px;
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
      color: lightgray;
      padding: 0px 5px;
    }
  }
  .user_profile {
    cursor: pointer;
    svg {
      font-size: 36px;
      color: whitesmoke;
      &:hover {
        color: black;
      }
    }
  }
  @media (max-width: 992px) {
    min-height: 60px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    .contact {
      width: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
    }
  }
}
