.Footer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid lightgray;
  background-color: #efeff0;
  min-height: 170px;
  .footerUpper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    border-bottom: 1px solid lightgray;
    .companyInfo {
      width: 245px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      .logo_area {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        border-radius: 100%;
        color: black;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
    .theCompnay {
      width: 245px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      .navbar_link {
        display: flex;
        text-decoration: none;
        color: blue;
      }
    }
    .terms {
      .FooterList {
        list-style: none;
        .FooterItem {
          text-decoration: none;
          color: blue;
        }
      }
    }
    .socials {
      width: 245px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      line-height: 25px;
      div {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
      }
    }
    .contact {
      width: 260px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      span {
        color: blue;
      }
    }
    .espa {
      width: 245px;
      min-height: 60px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
      .espa {
        min-height: 60px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        align-items: center;
      }
    }
  }
}
