.Navbar {
  width: 100%;
  z-index: 1000;
  position: relative;
  .lowerNav {
    min-height: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 992px) {
      display: block;
    }
    .left_side_navbar_component {
      display: flex;
      width: 180px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      @media (max-width: 992px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
      }
      .logo_area {
        width: 150px;
        height: 60px;
        img {
          width: 100%;
          height: 60px;
          display: block;
        }
      }
      .navbar_collapse {
        display: none;

        svg {
          font-size: 25px;
        }
        @media (max-width: 992px) {
          display: flex;
          align-items: center;
        }
      }
    }
    .navbar_links {
      max-width: 950px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 20px;
      align-items: center;
      a {
        text-decoration: none;
        color: #0f59ad;
      }
      .login {
        background-color: rgb(71, 103, 243);

        border-radius: 999px;
        a {
          color: rgb(255, 195, 65);
        }
        &:hover {
          background-color: rgb(4, 48, 243);
        }
      }
      .register {
        background-color: rgb(255, 195, 65);
        border-radius: 999px;
        a {
          color: rgb(71, 103, 243);
        }
        &:hover {
          background-color: rgb(250, 171, 2);
        }
      }

      .disabled {
        position: relative;
        script {
          position: absolute;
          top: 0px;
          right: 0px;
        }
      }
      .navlinkActive {
        color: blueviolet;
        border-bottom: 2px solid rgb(235, 176, 49);
      }
      @media (max-width: 992px) {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-end;
        padding-right: 15px;
      }
    }
    .navbar_links_hidden {
      display: none;
    }
    .right_side_navbar_component {
    }
  }
}
.NavbarActive {
  position: fixed;
  top: 0;
  .lowerNav {
    background-color: white;
  }
}
